import supportedLanguages from './supported-languages';
import defaultGlobalClientConfig, {
  IGlobalClientConfig,
} from '../../../default/config/GlobalClientConfig/GlobalClientConfig';
import Routes from '../../../../config/Routes/Routes';
import { MsisdnRequiredness } from '../../../../config/Enums/MsisdnRequiredness';

const GlobalClientConfig: IGlobalClientConfig = {
  ...defaultGlobalClientConfig,
  siteTitle: 'SLT eSports',
  clientName: 'SLT eSports',
  copyrightName: 'SLT eSports',
  clientOrganizationSiteUrl: 'https://www.slt.lk/esports',
  accountSetupRoute: Routes.subscribe,
  internationalization: {
    defaultLng: 'en',
    supportedLngs: supportedLanguages,
  },
  socialLinks: [
    { type: 'facebook', value: 'https://www.facebook.com/SLTeSports/' },
    { type: 'twitter', value: 'https://twitter.com/SriLankaTelecom' },
    { type: 'youtube', value: 'https://www.youtube.com/user/srilankatelecom' },
    { type: 'discord', value: 'https://discord.gg/UJr33KQrky' },
  ],
  nav: {
    orientation: 'vertical',
  },
  navigation: {
    ...defaultGlobalClientConfig.navigation,
    eventCategories: true,
  },
  currency: {
    showPlusTaxLabel: true,
    showOnlyPoints: true,
  },
  msisdn: {
    requiredness: MsisdnRequiredness.ABSENT,
  },
  storeBanner: (storePartnertId: string | undefined) =>
    `https://store-assets.arenaesports.ae/store-banners/headers/${storePartnertId}/header-image.png`,
};

export default GlobalClientConfig;
